import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { ActionIcon, Box, CopyButton, Grid, Group, Image, Paper, SimpleGrid, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { IconBox, IconCheck, IconCopy, IconCurrencyDollar, IconExternalLink, IconShoppingBag } from '@tabler/icons-react';
import classes from './../../styles/StatsSegments.module.css';
import { useStatistics } from '../../api';
import { DonutChart } from '@mantine/charts';
import { useTranslation } from 'react-i18next';
import { OrdersStatisticsTabel } from '../../componants/Tables';
import { sortedData } from '../../lib/sort';
//@ts-ignore
import { Helmet } from 'react-helmet';
import { ShowClientDrawer } from '../../componants/Drawer';

const cookies = new Cookies(null, { path: '/' });
interface Statistics {
    new: number,
    testing: number,
    payment: number,
    allClientToday: any[],
    todayOrders: number,
    beforeDayOrders: number,
    weekOrders: number,
    monthOrders: number,
}
/*
new: {
    total: statusNew + statusNewTesting + statusNewRejected,
    new: statusNew,
    newTesting: statusNewTesting,
    newRejected: statusNewRejected
},
testing: {
    total: statusTesting + statusTestingAttempted + statusTestingRejected,
    testing: statusTesting,
    testingAttempted: statusTestingAttempted,
    testingRejected: statusTestingRejected
},
payment: {
    total: statusPayment,
    basic: basicTheme,
    pro: proTheme,
    custom: customTheme
}
*/
export function Home () {
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState<Statistics>({
        new: 0,
        testing: 0,
        payment: 0,
        allClientToday: [],
        todayOrders: 0,
        beforeDayOrders: 0,
        weekOrders: 0,
        monthOrders: 0,
    });
    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditConfermerModal, setOpenEditConfermerModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [openEditStatusModal, setOpenEditStatusModal] = useState(false);

    const [selectedData, setSelectedData] = useState<any>(null);
    const theme = useMantineTheme();
    const {loading, error, data: dataStatistics, refetch} = useStatistics({ referralCode: cookies.get('ref') })
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])
    
    useEffect(() => {
        if (dataStatistics) {
            //@ts-ignore
            setStatistics(dataStatistics)
        }
    }, [dataStatistics])
    
    return (
        <Grid>
            <Grid.Col span={12}>
                <Box w={"100%"} h={190} bg={"#fff"} p={20}>
                    <Group justify='space-between' wrap='nowrap'>
                        <Stack justify='space-between' h={150}>
                            <Stack gap={15}>
                                <Text size='26px' fw={600} c={"dark.7"}>{t('homePage.title')} {cookies.get("name")} 👋</Text>
                                <Text size='sm' c={"gray.5"}>{t('homePage.description')}</Text>
                            </Stack>

                            <CopyButton value={`https://bi3li.shop/register/?ref=${cookies.get('ref')}`} timeout={2000}>
                                {({ copied, copy }) => (
                                    <Group>
                                        <Text size='14px' c={"#323232"} fw={700}>{`https://bi3li.shop/register/?ref=${cookies.get('ref')}`} </Text>
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                                {copied ? (<IconCheck size={14} />) : (<IconCopy size={14} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    </Group>
                                )}
                            </CopyButton>
                        </Stack>
                        <Image src={"/header.png"} height={150} width={"auto"} visibleFrom='md' />
                    </Group>
                </Box>
            </Grid.Col>

            <Grid.Col span={12} mb={10}>
                <Text fz="xl" fw={600}>{"احصائيات عامة"}</Text>
            </Grid.Col>

            <Grid.Col span={{base: 12, md: 4}}>
                <Paper withBorder p="md" radius="md">
                    <Group justify="space-between">
                        <Text fz="lg" fw={700}>{"عملاء"}</Text>
                        <IconBox size="1.4rem" className={classes.icon} stroke={1.5} />
                    </Group>

                    <Text c="dimmed" fz="sm">
                        {"إجمالي عدد العملاء"} {statistics?.new + statistics?.testing + statistics?.payment}
                    </Text>

                    <SimpleGrid bg={theme.colors.gray[0]} p={10} cols={{ base: 1 }} mt="xl" style={{justifyItems: 'center', gap: 0}}>
                        <DonutChart
                            withLabelsLine withLabels
                            data={[
                                { name: "عملاء جدد", value: statistics?.new || 0, color: 'blue' },
                                { name: "عملاء قيد التجريب", value: statistics?.testing || 0, color: 'grape' },
                                { name: "عملاء نهائيين", value: statistics?.payment || 0, color: 'lime' }
                            ]}
                        />
                        <Group justify='center'>
                            <Group gap={2} align='center'>
                                <Box bg={'blue'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"عملاء جدد"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'grape'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"عملاء قيد التجريب"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'lime'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"عملاء نهائيين"}</Text>
                            </Group>
                        </Group>
                    </SimpleGrid>
                </Paper>
            </Grid.Col>


            <Grid.Col span={{base: 12, md: 12}}>
                <Grid>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.todayOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات اليوم</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.beforeDayOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات امس</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.weekOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات اخر 7 ايام</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.monthOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات هذا الشهر</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Grid.Col>

            <Grid.Col span={12} mb={20}>
                <Text fz="xl" fw={600}>{"طلبات اخر 24 ساعة"}</Text>
            </Grid.Col>
            <OrdersStatisticsTabel
                //@ts-ignore
                data={sortedData(statistics?.allClientToday || [])}
            />
        </Grid>
    );
}