import React, { useEffect, useState } from 'react';
import { FinanceTabel } from '../../componants/Tables';
import { AddInvoice, DeleteInvoice, ShowIvoiceSites } from '../../componants/Modal';
import { HeadPage } from '../../componants';
import { Container, Group, SegmentedControl, Stack, Text, TextInput } from '@mantine/core';
import { IconLock, IconSearch } from '@tabler/icons-react';
import classes from './../../styles/Product.module.css';
import { searchSortedData, sortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';
import { useFinances } from '../../api';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies(null, { path: '/' });

export function Finance () {
    const { t } = useTranslation();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);

    const [selectedData, setSelectedData] = useState<any>(null);

    const [search, setSearch] = useState('');

    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);

    const {loading, error, data: dataOrders, refetch} = useFinances({
        idMarketer: cookies.get('id')
    })
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders)
            const newData = sortedData(dataOrders)
            setOrdersSorted(newData)
        }
    }, [dataOrders])

    useEffect(() => {
        if (search !== "") {
            const newData = searchSortedData(orders, ["name", "phone", "email"], search)
            setOrdersSorted(newData)
        } else {
            const newData = sortedData(orders)
            setOrdersSorted(newData)
        }
    }, [search])

    return (
        <>
            <HeadPage
                page={"المالية"}
                links={[
                    { title: "لوحة التحكم", href: '/dashboard' },
                    { title: "قائمة الفواتير", href: '' }
                ]}
                labelExport={t('ordersPage.labelExport')}
                labelCreate='اضافة فاتورة'
                hiddenExport={true}
                onCreate={() => setOpenAddModal(true)}
                onExport={() => console.log()}
            />

            <FinanceTabel
                data={ordersSorted}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEditModal={setOpenEditModal}
                setOpenShowModal={setOpenShowModal}
                setSelectedData={setSelectedData}
                loading={loading}
            />

            <AddInvoice title={"إضافة فاتورة "} refetchData={refetch} data={selectedData} opened={openAddModal} onClose={() => setOpenAddModal(false)} />
            <ShowIvoiceSites data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />
        </>
    );
}