import { Box, Button, Grid, Group, MultiSelect, NumberInput, Select, TagsInput, Textarea } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconFileTypePdf, IconX } from "@tabler/icons-react";
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useClients } from "../../api";
import { ComboboxSites } from "../Custom/ComboboxSites";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const AddInvoice = (props: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [allSites, setAllSites] = useState<{ label: string, value: string, price: number, version: string }[]>([]);
    const [selectedSites, setSelectedSites] = useState<string[]>([]);
    const [totalPriceSites, setTotalPriceSites] = useState<number>(0);
    const {loading: loadingOrders, error, data: dataSits, refetch} = useClients({ 
        status: "payment", accounting: false,
        referralCode: cookies.get('ref')
    })
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: { price: 0, note: ""}
    });
    useEffect(() => {
        if (!dataSits) {
            refetch()
        }
    }, [])

    useEffect(() => {
        if (dataSits && dataSits?.length >= 0) {
            let newData: { label: string, value: string, price: number, version: string }[] = []
            for (let i = 0; i < dataSits.length; i++) {
                const element = dataSits[i];
                if (element?.name && element?.name !== "") {
                    newData.push({
                        label: element?.name,
                        value: element?._id,
                        version: element?.version,
                        price: element?.version === "basic" ? 2500 :
                            element?.version === "pro" ? 3500 :
                            element?.version === "custom" ? 3500 : 0
                    })
                }
            }
            setAllSites(newData)
        }
    }, [dataSits])

    useEffect(() => {
        if (totalPriceSites >= 0) {
            setValues({ price: totalPriceSites })
        }
    }, [totalPriceSites])

    const onSubmitForm = ({price, note}: any) => {
        setLoading(true)

        let sites: { _id: string, site: string, version: string, price: number}[] = []
        for (let i = 0; i < allSites.length; i++) {
            const element = allSites[i];
            if (selectedSites.includes(element.value)) {
                sites.push({_id: element.value, site: element.label, price: element.price, version: element.version})
            }
        }
        
        client.post(`/invoices`, {
            note,
            price,
            sites: sites,
            type: "receiving",
            status: "pending",
            idMarketer: cookies.get('id')
        }, {
            headers: {'Accept': 'application/json', 'Authorization': cookies.get('token') || ""}
        })
        .then(({data}) => {
            notifications.show({ message: "تم اضافة الفاتورة", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل اضافة الفاتورة", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
        setAllSites([])
        setSelectedSites([])
        setTotalPriceSites(0)
    };

    return (
        <>
            <Modal
                {...props} onClose={closeModal} loading={loading}
                footer={
                    <Box py={16} px={20} bg="slate.0">
                        <Group justify={"flex-end"} gap={"xs"}>
                            <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                            <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                        </Group>
                    </Box>
                }
            >
                <Box style={{padding: 20}}>
                    <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                        <Grid gutter={20} justify="flex-start">
                            <Grid.Col span={{base: 12, md: 6}} >
                                <NumberInput
                                    label={"السعر"}
                                    placeholder={"السعر"}
                                    {...getInputProps("price")}
                                    readOnly
                                />
                            </Grid.Col>

                            <Grid.Col span={{base: 12, md: 6}} >
                                <ComboboxSites
                                    sites={allSites}
                                    value={selectedSites}
                                    setValue={setSelectedSites}
                                    setTotalPriceSites={setTotalPriceSites}
                                />
                            </Grid.Col>

                            <Grid.Col span={12} >
                                <Textarea
                                    label={"ملاحظة"}
                                    placeholder={"ملاحظة"}
                                    {...getInputProps("note")}
                                    rows={3}
                                />
                            </Grid.Col>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    );
};