import { Box, Button, Divider, Grid, Group, NumberInput, PasswordInput, Select, Stack, Text, TextInput, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconCloudUpload, IconPhoto, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Wilayas from '../../helper/wilayas.json';
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const AddClient = (props: Props) => {
    const { t } = useTranslation();
    
    const schema = z.object({
        name: z.string({message: "اسم العميل اجباري"}).min(2, { message: "اسم العميل يجب ان يكون اطول من حرفين" }),
        phone: z.string({message: "رقم الهاتف اجباري"}).min(10, { message: "رقم الهاتف يجب ان يكون 10 ارقام" }).max(10, { message: "رقم الهاتف يجب ان يكون 10 ارقام" }),
        email: z.string({message: "البريد الالكتروني اجباري"}).min(2, { message: "البريد الالكتروني يجب ان يكون اطول من حرفين" }),
    });
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: { 
            name: "", phone: "", email: "", theme: "nova", price: 15000, methodPay: "", version: "basic",
            storeUrl: "",
            externalHost: {
                url: "",
                userName: "",
                password: ""
            }
        },
        validate: zodResolver(schema),
        validateInputOnBlur: true,
        validateInputOnChange: true
    });
    const [proofFile, setProofFiles] = useState<File[]>([]);
    const [typeLink, setTypeLink] = useState<string | null>("internal");

    useEffect(() => {
        if (values.version) {
            setValues({
                price: values.version === "basic" ? 15000 : values.version === "pro" ? 21000 : 15000
            })
        }
    }, [values.version])

    const onSubmitForm = ({name, phone, email, theme, version, methodPay, price, storeUrl, externalHost}: any) => {
        setLoading(true)
        let dataForm = new FormData();
        dataForm.append('name', name);
        dataForm.append('phone', phone);
        dataForm.append('email', email);
        dataForm.append('theme', theme);
        dataForm.append('version', version);
        dataForm.append('referralCode', cookies.get('ref') || "");
        dataForm.append('storeUrl', storeUrl);
        dataForm.append('externalHost', JSON.stringify(externalHost));

        dataForm.append('purchaseInfo', JSON.stringify({'price': price, 'methodPay': methodPay}));
        dataForm.append('proof', proofFile[0]);
        
        client.post(`/clientsWithReferralCode`, dataForm, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            if ("code" in data && data.code === "EMAIL_ALREADY_EXIST") {
                notifications.show({ message: "فشل اضافة هذا العميل موجود مسبقا", color: 'red' });
            } else {
                notifications.show({ message: "تم اضافة العميل", color: '#fff' });
                typeof props.refetchData == "function" && props.refetchData()
                closeModal()
            }
            setLoading(false)
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل اضافة العميل", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={{base: 12, md: 6}} >
                            <TextInput
                                label={"اسم العميل"}
                                placeholder={"اسم العميل"}
                                withAsterisk
                                {...getInputProps("name")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <TextInput
                                label={"رقم الهاتف"}
                                placeholder={"رقم الهاتف"}
                                withAsterisk
                                {...getInputProps("phone")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <TextInput
                                label={"البريد الالكتروني"}
                                placeholder={"البريد الالكتروني"}
                                withAsterisk
                                {...getInputProps("email")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <Select
                                label={"الثيم"}
                                placeholder={"الثيم"}
                                data={[
                                    {label: "Nova", value: "nova"},
                                    {label: "Solo", value: "solo"},
                                    {label: "Nexa", value: "nexa"}
                                ]}
                                withAsterisk
                                {...getInputProps("theme")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <Select
                                label={"الخطة"}
                                placeholder={"الخطة"}
                                data={[
                                    {label: "الخطة البسيطة", value: "basic"},
                                    {label: "الخطة الاحترافية", value: "pro"},
                                    {label: "الخطة المخصصة", value: "custom"}
                                ]}
                                withAsterisk
                                {...getInputProps("version")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <NumberInput
                                label={"قيمة الدفع"}
                                placeholder={"قيمة الدفع"}
                                withAsterisk
                                {...getInputProps("price")}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12, md: 6}} >
                            <Select
                                label={"طريقة الدفع"}
                                placeholder={"طريقة الدفع"}
                                data={[
                                    {label: "كاش", value: "cash"},
                                    {label: "بريدي موب", value: "baridimob"},
                                    {label: "حوالة", value: "transfer"},
                                    {label: "Wise", value: "wise"},
                                    {label: "Paypal", value: "paypal"},
                                    {label: "Paysera", value: "paysera"}
                                ]}
                                withAsterisk
                                {...getInputProps("methodPay")}
                            />
                        </Grid.Col>
                        
                        <Grid.Col span={{base: 12, md: 6}} >
                            <Select
                                label={"نوع الرابط"}
                                placeholder={"نوع الرابط"}
                                data={[
                                    {label: "داخلي", value: "internal"},
                                    {label: "خارجي", value: "external"}
                                ]}
                                value={typeLink}
                                onChange={setTypeLink}
                                allowDeselect={false}
                            />
                        </Grid.Col>
                
                
                        <Grid.Col span={12} >
                            <TextInput
                                label={"رابط المتجر"}
                                placeholder={"رابط المتجر"}
                                withAsterisk
                                {...getInputProps("storeUrl")}
                            />
                        </Grid.Col>
                        {typeLink == "external"
                            ? <>
                                <Grid.Col span={12} mt={20}>
                                    <Divider labelPosition="left" label={<Text size="sm">المعلومات الخارجية :</Text>} />
                                </Grid.Col>
                                <Grid.Col span={12} >
                                    <TextInput
                                        label={"رابط لوحة التحكم"}
                                        placeholder={"رابط لوحة التحكم"}
                                        {...getInputProps("externalHost.url")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6} >
                                    <TextInput
                                        label={"اسم المستخدم"}
                                        placeholder={"اسم المستخدم"}
                                        {...getInputProps("externalHost.userName")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6} >
                                    <PasswordInput
                                        label={"كلمة المرور"}
                                        placeholder={"كلمة المرور"}
                                        {...getInputProps("externalHost.password")}
                                    />
                                </Grid.Col>
                            </>
                            : null
                        }
                        

                        <Grid.Col span={12} >
                            <label style={{fontSize: 16}}>
                                {"إثبات الدفع"}
                                {proofFile.length > 0 ? <Text span c={"green"} ms={5}>تم الرفع</Text> : ""}
                            </label>
                            <Dropzone
                                onDrop={(files: any) => setProofFiles(files)}
                                onReject={(files) => console.log('rejected files', files)}
                                maxSize={10 * 1024 ** 2}
                                accept={IMAGE_MIME_TYPE} mt={20}
                            >
                                <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                    <Dropzone.Accept>
                                        <IconUpload
                                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                            stroke={1.5}
                                        />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                        <IconX
                                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                            stroke={1.5}
                                        />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                        <Stack align="center" justify="center" gap={2}>
                                            <IconCloudUpload size={40} style={{ color: 'var(--mantine-color-gray-8)' }} stroke={1} />
                                            <Text size="lg" mt={10}>{"اسحب الصورة هنا أو انقر لتحديد الصورة"}</Text>
                                            <Text size="sm" c="dimmed">{"قم بإرفاق الصورة ويجب ألا يتجاوز حجمها 5 ميجابايت"}</Text>
                                        </Stack>
                                    </Dropzone.Idle>
                                </Group>
                            </Dropzone>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};