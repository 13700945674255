import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { OrdersTabel } from '../../componants/Tables';
import { HeadPage } from '../../componants';
import { Group, SegmentedControl, TextInput } from '@mantine/core';
import { IconLock, IconSearch } from '@tabler/icons-react';
import classes from './../../styles/Product.module.css';
import { searchSortedData, sortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';
import { useClients, useSearchOrders } from '../../api';
import { ShowClientDrawer } from '../../componants/Drawer';
import { useTranslation } from 'react-i18next';
import { AddClient } from '../../componants/Modal/AddClient';
import { useMediaQuery } from '@mantine/hooks';

const cookies = new Cookies(null, { path: '/' });

export function Orders () {
    const { t } = useTranslation();
    const matches = useMediaQuery('(min-width: 900px)');
    
    const [search, setSearch] = useState('');
    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);

    const [status, setStatus] = useState("new");
    
    const {loading, error, data: dataOrders, refetch} = useClients({ status, referralCode: cookies.get('ref') })
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders)
            const newData = sortedData(dataOrders)
            setOrdersSorted(newData)
        }
    }, [dataOrders])

    useEffect(() => {
        if (search !== "") {
            const newData = searchSortedData(orders, ["name", "phone", "email"], search)
            setOrdersSorted(newData)
        } else {
            const newData = sortedData(orders)
            setOrdersSorted(newData)
        }
    }, [search])

    return (
        <>
            <HeadPage
                page={"العملاء الجدد"}
                links={[
                    { title: "لوحة التحكم", href: '/dashboard' },
                    { title: "قائمة العملاء الجدد", href: '' }
                ]}
                labelExport={t('ordersPage.labelExport')}
                hiddenExport={true}
                onCreate={() => console.log()}
                onExport={() => console.log()}
                // onExport={openExportDialog}
            />

            <Group justify='space-between' align='flex-end' mb={20}>
                <SegmentedControl
                    withItemsBorders={false} 
                    value={status}
                    onChange={setStatus}
                    data={[
                        {label: "طلب جديد", value: "new"},
                        {label: "قيد التجريب", value: "testing"},
                        {label: "طلب نهائي", value: "payment"}
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center",
                            overflowX: 'auto'
                        },
                        indicator: {
                            background: "#F2F7FB", display: "none",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />
                
                <TextInput
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18} />}
                    placeholder={t('ordersPage.placeholder')}
                    styles={{
                        input: {height: 40}
                    }}
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                />
            </Group>

            <OrdersTabel
                data={ordersSorted}
                loading={loading}
            />
        </>
    );
}