import { ActionIcon, Badge, Box, CopyButton, Drawer, DrawerProps, Grid, Group, List, Stack, Tabs, Text, Timeline, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy, IconExternalLink, IconEye, IconEyeClosed, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import Avatar from 'react-avatar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../api';
import { sortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });
type Props = {
    data?: any;
    setSelectedData?: (id: string) => void;
} & DrawerProps;

const allStatus = {
    "new": "طلب جديد",
    "new-testing": "طلب جديد يتم المحاولة فيه",
    "new-rejected": "طلب جديد مرفوض",
    "testing": "قيد التجريب",
    "testing-attempted": "قيد التجريب يتم المحاولة فيه",
    "testing-rejected": "قيد التجريب مرفوض",
    "payment": "طلب مرفوض"
}

export function ShowClientDrawer(props: Props) {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<string | null>('tab1');
    const [client, setClient] = useState<any>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>(null);
    const {loading, error, data: dataUser, refetch} = useUsers()
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if ("data" in props && props.data !== null) {
            setClient(props?.data)
        }
    }, [props.data])

    const closeModal = () => {
        props.onClose();
        setShowPassword(false)
    };
    const getUser = (userID: string) => {
        const user = dataUser?.filter((item: any) => item._id == userID)
        if (user.length > 0) {
            return user[0].name as string
        } else {
            return ""
        }
    }

    const reverseTimeLine = (data: any) => {
        const reversed = sortedData(data)

        return reversed
    }

    return (
        <Drawer 
            {...props} onClose={closeModal}
            styles={{
                content: {background: "#eee"},
                header: {background: "#fff", padding: 0},
                title: {height: 60}
            }}
            title={
                <Tabs
                    defaultValue={activeTab} value={activeTab} onChange={setActiveTab}
                    styles={{
                        tab: {
                            height: 60,
                            paddingLeft: 0,
                            paddingRight: 0
                        },
                        list: {
                            flexWrap: 'nowrap',
                            // overflowX: 'scroll',
                            gap: 10
                        }
                    }}
                >
                    <Tabs.List>
                        <Tabs.Tab value="tab1" >
                            معلومات الطلب
                        </Tabs.Tab>
                        <Tabs.Tab value="tab2" >
                            حالات الطلب
                        </Tabs.Tab>
                        {client?.connectHistory?.length > 0
                            ? <Tabs.Tab value="tab3" >سجل الاستخدام</Tabs.Tab>
                            : null
                        }
                        {client?.mailTimeLine?.length > 0
                            ? <Tabs.Tab value="tab4" >سجل البريد</Tabs.Tab>
                            : null
                        }
                        
                    </Tabs.List>
                </Tabs>
            }
        >
            <Tabs defaultValue={activeTab} value={activeTab} onChange={setActiveTab} >
                <Tabs.Panel value="tab1" >
                    <Grid gutter={15} justify="center" h={"100%"}>
                        <Grid.Col span={12} mt={20}>
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{"اسم العميل :"}</Text>
                                <Text fw={600}>{client?.name}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{"رقم الهاتف :"}</Text>
                                <Text fw={600}>{client?.phone}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{"البريد الالكتروني :"}</Text>
                                <Text fw={600}>{client?.email}</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{"الثيم :"}</Text>
                                <Text fw={600}>{
                                    client?.theme === "nova" ? <Badge radius={'sm'} color='green' variant='filled'>{"Nova"}</Badge> 
                                    : client?.theme === "nexa" ? <Badge radius={'sm'} color='#d44c2e' variant='filled'>{"Nexa"}</Badge> 
                                        : client?.theme === "solo" ? <Badge radius={'sm'} color='purple' variant='filled'>{"Solo"}</Badge> 
                                            : client?.theme === "anaqati" ? <Badge radius={'sm'} color='#b07d10' variant='filled'>{"Anaqati"}</Badge> : null
                                }</Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                <Text fw={400} c={"gray.7"}>{"الخطة :"}</Text>
                                <Text fw={600}>{
                                    client?.version === "basic" ? <Badge radius={'sm'} color='blue' variant='filled'>{"الخطة البسيطة"}</Badge> 
                                        : client?.version === "pro" ? <Badge radius={'sm'} color='grape' variant='filled'>{"الخطة الاحترافية"}</Badge> 
                                            : client?.version === "custom" ? <Badge radius={'sm'} color='lime' variant='filled'>{"الخطة المخصصة"}</Badge> : null
                                }</Text>
                            </Group>
                        </Grid.Col>
                        {client?.purchaseInfo?.price
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"قيمة الدفع :"}</Text>
                                    <Text fw={600}>{client?.purchaseInfo?.price} دج</Text>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {client?.purchaseInfo?.methodPay
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"طريقة الدفع :"}</Text>
                                    <Text fw={600}>{
                                        client?.purchaseInfo?.methodPay === "cash" ? <Badge radius={'sm'} color='teal' variant='filled'>{"كاش"}</Badge>
                                            : client?.purchaseInfo?.methodPay === "baridimob" ? <Badge radius={'sm'} color='blue' variant='filled'>{"بريدي موب"}</Badge>
                                                : client?.purchaseInfo?.methodPay === "transfer" ? <Badge radius={'sm'} color='yellow' variant='filled'>{"حوالة"}</Badge>
                                                : <Badge radius={'sm'} color='#012069' variant='filled'>{client?.purchaseInfo?.methodPay}</Badge>
                                    }</Text>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        {client?.purchaseInfo?.proof
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"اثبات الدفع :"}</Text>
                                    <Badge variant='outline' size='lg' leftSection={<IconExternalLink size={14} />} >
                                        <Text size='xs' fw={600} component='a' target='_blank' href={`${process.env.REACT_APP_API_URL_IMAGES}/${client?.purchaseInfo?.proof}`} >رابط الإثبات</Text>
                                    </Badge>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        
                        
                        

                        {client?.adminUrl && client?.adminUrl !== ""
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"لوحة التحكم :"}</Text>
                                    <CopyButton value={client?.adminUrl} timeout={2000}>
                                        {({ copied, copy }) => (
                                            <Badge
                                                variant='outline' size='lg'
                                                rightSection={
                                                    <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                                        <ActionIcon size={20} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                                            {copied ? (<IconCheck size={14} />) : (<IconCopy size={14} />)}
                                                        </ActionIcon>
                                                    </Tooltip>
                                                }
                                            >
                                                <Text size='xs' fw={600} component='a' target='_blank' href={client?.adminUrl} >الرابط</Text>
                                            </Badge>
                                        )}
                                    </CopyButton>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        
                        {client?.userName && client?.userName !== ""
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"اسم المستخدم :"}</Text>
                                    <Text fw={600}>{client?.userName}</Text>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        
                        {client?.password && client?.password !== ""
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"كلمة المرور :"}</Text>
                                    <Badge
                                        variant='outline'
                                        rightSection={
                                            <ActionIcon size={20} color={'gray'} variant="subtle" onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? (<IconEye size={14} />) : (<IconEyeClosed size={14} />)}
                                            </ActionIcon>
                                        }
                                        styles={{
                                            root: {textTransform: "none"}
                                        }}
                                    >
                                        <Text size='xs' fw={600} >{showPassword ? client?.password : "****"}</Text>
                                    </Badge>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        
                        {client?.storeUrl && client?.storeUrl !== ""
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"المتجر :"}</Text>
                                    <CopyButton value={client?.storeUrl} timeout={2000}>
                                        {({ copied, copy }) => (
                                            <Badge
                                                variant='outline' size='lg'
                                                rightSection={
                                                    <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                                        <ActionIcon size={20} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                                            {copied ? (<IconCheck size={14} />) : (<IconCopy size={14} />)}
                                                        </ActionIcon>
                                                    </Tooltip>
                                                }
                                            >
                                                <Text size='xs' fw={600} component='a' target='_blank' href={client?.storeUrl} >الرابط</Text>
                                            </Badge>
                                        )}
                                    </CopyButton>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                        
                        {client?.apiUrl && client?.apiUrl !== ""
                            ? <Grid.Col span={12} >
                                <Group justify='space-between' bg={"#fff"} p={10} style={{borderRadius: 5}}>
                                    <Text fw={400} c={"gray.7"}>{"رابط API :"}</Text>
                                    <CopyButton value={client?.apiUrl} timeout={2000}>
                                        {({ copied, copy }) => (
                                            <Badge
                                                variant='outline' size='lg'
                                                rightSection={
                                                    <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                                        <ActionIcon size={20} color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                                            {copied ? (<IconCheck size={14} />) : (<IconCopy size={14} />)}
                                                        </ActionIcon>
                                                    </Tooltip>
                                                }
                                            >
                                                <Text size='xs' fw={600} component='a' target='_blank' href={client?.apiUrl} >الرابط</Text>
                                            </Badge>
                                        )}
                                    </CopyButton>
                                </Group>
                            </Grid.Col>
                            : null
                        }
                    </Grid>
                </Tabs.Panel>

                <Tabs.Panel value="tab2" >
                    {client?.timeLine?.length > 0
                        ? <Timeline mt={50} active={client?.timeLine?.length - 1} bulletSize={18} lineWidth={1.5}>
                            {reverseTimeLine(client?.timeLine)?.map((item: any, index: number) => (
                                <Timeline.Item
                                    key={index}
                                    //@ts-ignore
                                    title={
                                        <Group justify='space-between' align='center'>
                                            <Text c="dimmed" size="sm" display={'flex'} style={{alignItems: 'center'}}>
                                                <Avatar name={getUser(item?.userId)} size="20" round={true} style={{marginLeft: 5}} />
                                                {getUser(item?.userId)}
                                            </Text>
                                            <Group justify='flex-end' align='center' gap={5}>
                                                {item?.status
                                                    ? <Badge fw={'normal'}>{item?.status}</Badge>
                                                    : null
                                                }

                                                {["admin"].includes(role)
                                                    ? <ActionIcon
                                                        variant={"transparent"} color={"red"}
                                                        onClick={() => {
                                                            setOpenDeleteModal(true)
                                                            setSelectedData(item?._id)
                                                        }}
                                                    >
                                                        <IconTrash size="0.9rem" />
                                                    </ActionIcon>
                                                    : null
                                                }
                                            </Group>
                                        </Group>
                                    }
                                    styles={{
                                        itemBody: {
                                            background: "#fff",
                                            padding: 10
                                        },
                                        itemTitle: {
                                            fontSize: 14
                                        }
                                    }}
                                >
                                    {/* <Text c="dimmed" size="sm">{item?.note}</Text> */}
                                    <Box mt={20} c={"gray.6"} dangerouslySetInnerHTML={{__html: item?.note}} />
                                    <List listStyleType="none" bg={"#fff"} p={20} display={item?.recordings && item?.recordings?.length > 0 ? "block" : "none"}>
                                        {item?.recordings?.map((item: any, index: number) => (
                                            <List.Item key={index}>
                                                <audio src={`${process.env.REACT_APP_API_URL_IMAGES}/${item}`} controls />
                                            </List.Item>
                                        ))}
                                    </List>
                                    <Group justify='flex-end' align='center'>
                                        <Text size="xs" mt={4}>{dayjs(item?.createdAt).locale("ar").fromNow()}</Text>
                                    </Group>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        : null
                    }
                </Tabs.Panel>

                <Tabs.Panel value="tab3" >
                    {client?.connectHistory?.length > 0
                        ? <Timeline mt={50} active={client?.connectHistory?.length - 1} bulletSize={0} lineWidth={1.5}>
                            {client?.connectHistory?.map((item: any, index: number) => (
                                <Timeline.Item
                                    key={index}
                                    //@ts-ignore
                                    title={
                                        <Stack justify='space-between' align='flex-start'>
                                            <Text c="dimmed" size="sm" display={'flex'} style={{alignItems: 'center'}}>
                                                <Text fw={'bold'} me={5}>دخول : </Text>
                                                {item?.connectAt ? dayjs(item?.connectAt).format("YYYY-MM-DD HH:mm:ss") : ""}
                                            </Text>
                                            <Text c="dimmed" size="sm" display={'flex'} style={{alignItems: 'center'}}>
                                                <Text fw={'bold'} me={5}>خروج : </Text>
                                                {item?.desconnectAt ? dayjs(item?.desconnectAt).format("YYYY-MM-DD HH:mm:ss") : ""}
                                            </Text>
                                            <Badge fw={'normal'}>{
                                                dayjs(item?.desconnectAt).diff(item?.connectAt, 'minutes') == 0
                                                ? "اقل من دقيقة"
                                                : `${dayjs(item?.desconnectAt).diff(item?.connectAt, 'minutes')} دقيقة`
                                            }</Badge>
                                        </Stack>
                                    }
                                    styles={{
                                        itemBody: {
                                            background: "#fff",
                                            padding: 10
                                        },
                                        itemTitle: {
                                            fontSize: 14
                                        }
                                    }}
                                >
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        : null
                    }
                </Tabs.Panel>
                <Tabs.Panel value="tab4" >
                    {client?.mailTimeLine?.length > 0
                        ? <Timeline mt={50} active={client?.mailTimeLine?.length - 1} bulletSize={10} lineWidth={1}>
                            {client?.mailTimeLine?.map((item: any, index: number) => (
                                <Timeline.Item
                                    key={index}
                                    //@ts-ignore
                                    title={
                                        <Stack justify='space-between' align='flex-start'>
                                            <Text c="dimmed" size="sm" display={'flex'} style={{alignItems: 'center'}}>
                                                {item?.type === "welcomeMsg" 
                                                    ? "رسالة ترحيب"
                                                    : item?.type === "dataLogin" 
                                                        ? "معلومات النسخة التجريبية" : ""
                                                }
                                            </Text>
                                            <Group justify='space-between' w={"100%"}>
                                                {item?.status === "pending" 
                                                    ? <Badge fw={'normal'} variant='light' color='yellow'>{"قيد الانتظار"}</Badge>
                                                    : item?.status === "opened" ? <Badge fw={'normal'} variant='light' color='green'>{"تم الفتح"}</Badge>
                                                    : item?.status === "opened" ? <Badge fw={'normal'} variant='light' color='red'>{"خطأ في الارسال"}</Badge> : ""
                                                }
                                                
                                                <Text c="gray.6" size="xs" ta={'right'}>
                                                    {dayjs(item?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                                </Text>
                                            </Group>
                                        </Stack>
                                    }
                                    styles={{
                                        itemBody: {
                                            background: "#fff",
                                            padding: 10
                                        },
                                        itemTitle: {
                                            fontSize: 14
                                        }
                                    }}
                                >
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        : null
                    }
                </Tabs.Panel>
            </Tabs>
        </Drawer>
    );
}